import React from "react";
import ContactApp from "~sections/contact/ContactOne/ContactSection";
import FooterSection from "~sections/about/Footer";
import { PageWrapper } from "~components/Core";

export default function contactOne() {
  return (
    <PageWrapper innerPage={true}>
        <ContactApp/>
        <FooterSection/>
    </PageWrapper>
  )
}
